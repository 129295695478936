
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import { object, string, number } from "yup";

import membersApi from "@/core/services/MembersApi";
import accountingApi from "@/core/services/AccountingApi";
import { hideModal } from "@/core/helpers/dom";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
    name: "Biller details",
    components: { Form, Field },
    data() {
        return {
            // Operations
            loading: true,
            editing: false,
            title: "" as any,
            intoOperations: true,
            intoSynthese: false,
            intoContacts: false,
            billerLabel: "",
            billerYear: "",
            filters: {
                dateFrom: "",
                dateTo: "",
                operationType: "",
                relevant_year: "",
                bailliage: "",
            },
            opeIdToDelete: "",
            operations: [
                {
                    id: 0,
                    label: "Virement",
                },
                {
                    id: 1,
                    label: "Crédit",
                },
                {
                    id: 2,
                    label: "Transfert",
                },
                {
                    id: 3,
                    label: "CDR Dues",
                },
                {
                    id: 4,
                    label: "CDR Admission",
                },
                {
                    id: 5,
                    label: "OMGD Dues",
                },
                {
                    id: 6,
                    label: "OMGD Admission",
                },
                {
                    id: 7,
                    label: "CDR Reinsertion",
                },
                {
                    id: 8,
                    label: "OMGD Admission",
                },
            ],
            opeLabels: [
                {
                    label: "Due",
                    value: "COT",
                },
                {
                    label: "Reinstatement",
                    value: "REI",
                },
                {
                    label: "Admission",
                    value: "ADM",
                },
                {
                    label: "Penalty",
                    value: "PEN",
                },
                {
                    label: "Wire payment",
                    value: "WIR",
                },
                {
                    label: "CB payment",
                    value: "CBP",
                },
                {
                    label: "Invoice",
                    value: "INV",
                },
            ],
            bailliages: [] as any,
            nBailliages: [] as any,
            operationsList: [] as any,
            remoteMembers: [] as any,
            newContactName: "",

            newOperation: {
                relevant_year: "",
                perimeter: "",
                type: "",
                label: "",
                description: "",
                bailliage: "",
                quantity: "",
                unit_price: "",
            },
            operationDetails: {},
            billerTotal: {},

            // Synthese
            syntheseCDR: [] as any,
            syntheseOMGD: [] as any,
            relevantYear: "",

            // Contacts
            newContact: {
                id: "",
                type: "member",
                userprofile: {
                    id: "",
                    lastname: "",
                    firstname: "",
                },
                bailliage: "",
                email: "",
                to: "",
                status: "",
                add_to_all: false,
            },

            main_contacts: [] as any,
            additional_contacts: [] as any,
            perm_pos: "" as any,
        };
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const addOperationRef = ref<null | HTMLElement>(null) as any;
        const addContactModalRef = ref<null | HTMLElement>(null) as any;

        const operationSchema = object().shape({
            opeRelevantYear: string().required().label("Relevant year").nullable(),
            opeType: string().required().label("Operation type").nullable(),
            opeLabel: string().required().label("Operation label").nullable(),
            opePerimeter: string().required().label("Operation perimeter").nullable(),
            opeDescription: string().required().label("Operation description").nullable(),
            opeBailliage: string().required().label("Operation bailliage").nullable(),
            opeQuantity: number().required().typeError("Invalid quantity").nullable(),
            opePrice: number().min(0).required().typeError("Invalid unit price").nullable(),
        });

        const contactExternSchema = object().shape({
            contactType: string().required().label("Contact type").nullable(),
            contactLastname: string().required().label("Contact lastname").nullable(),
            contactFirstname: string().required().label("Contact firstname").nullable(),
            contactBailliage: string().required().label("Contact bailliage").nullable(),
            contactEmail: string().email().required().label("Contact email").nullable(),
            contactTo: string().required().label("Contact To").nullable(),
            contactStatus: string().required().label("Contact status").nullable(),
        });

        const contactMemberSchema = object().shape({
            contactType: string().required().label("Contact type").nullable(),
            contactName: string().required().label("Contact name").nullable(),
            contactEmail: string().email().label("Contact email").nullable(),
            contactTo: string().required().label("Contact To").nullable(),
            contactStatus: string().required().label("Contact status").nullable(),
        });

        const backToList = () => {
            router.push({ name: "accounting" });
        };

        const closeOperationModal = () => {
            hideModal(addOperationRef.value);
        };

        const closeContactsModal = () => {
            hideModal(addContactModalRef.value);
        };

        const formatter = new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
        });

        return {
            backToList,
            closeOperationModal,
            closeContactsModal,
            route,
            operationSchema,
            addOperationRef,
            addContactModalRef,
            contactExternSchema,
            contactMemberSchema,
            formatter,
        };
    },
    mounted() {
        this.title = document.querySelector("#title-breadcrumbs h1") as HTMLElement;
        if (this.title) this.title.style.cssText = "display: none!important;";
        this.loading = true;
        this.getFields();
        this.perm_pos = localStorage.getItem("perm_pos");
    },
    beforeUnmount() {
        this.title.style.cssText = "display: flex !important;";
    },
    methods: {
        getMonth(index: any) {
            const months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return months[index];
        },
        resetForm(id) {
            this.newOperation = {
                relevant_year: "",
                perimeter: "",
                type: "",
                label: "",
                description: "",
                bailliage: "",
                quantity: "",
                unit_price: "",
            };

            this.newContactName = "";

            this.newContact = {
                id: "",
                type: "member",
                userprofile: {
                    id: "",
                    lastname: "",
                    firstname: "",
                },
                bailliage: "",
                email: "",
                to: "",
                status: "",
                add_to_all: false,
            };
            setTimeout(() => {
                (document.querySelector("#" + id) as any).click();
            }, 50);
        },
        viewOperation(ope: any) {
            this.operationDetails = ope;
        },
        getFields() {
            this.loading = true;
            // Get Bailliages
            membersApi.getBailliages().then((res) => {
                this.nBailliages = [];
                var bailliages = res.data;
                bailliages.map((bailliage: any) => {
                    if (bailliage.type !== "National") this.bailliages.push(bailliage);
                    else this.nBailliages.push(bailliage);
                });
            });

            // Get operations
            accountingApi.listOperations({ biller: this.route.params.biller_id, tab: "operations" }).then((res) => {
                this.operationsList = res.data.operations;
                this.billerLabel = res.data.national_bailliage;
                this.billerYear = res.data.year;
                setCurrentPageBreadcrumbs(this.billerLabel + " Biller", [
                    {
                        link: "/accounting/",
                        label: "Accounting",
                    },
                ]);
                this.billerTotal = {
                    debit: res.data.operations.total_biller.debit,
                    credit: res.data.operations.total_biller.credit,
                    balance: res.data.operations.total_biller.current_balance,
                };

                var array = [] as any;

                delete this.operationsList.total_biller;
                for (const [key, value] of Object.entries(this.operationsList) as any) {
                    var operation = {
                        id: Math.floor(Math.random() * 1000000),
                        label: key,
                        debit: value[0].debit,
                        credit: value[0].credit,
                        balance: value[0].total,
                        ope: value[1],
                    };

                    array.push(operation);
                }
                this.operationsList = array;
                this.loading = false;
            });

            // Get syntheses
            accountingApi.listOperations({ biller: this.route.params.biller_id, tab: "synthese" }).then((res: any) => {
                if (res.success) {
                    this.syntheseCDR = res.data.operations.CDR;
                    this.syntheseOMGD = res.data.operations.OMGD;
                    this.relevantYear = res.data.year;
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while getting syntheses.",
                    });
                }
            });

            // Get contacts
            this.listContacts();
        },
        listContacts() {
            this.main_contacts = [];
            this.additional_contacts = [];
            accountingApi.listContacts({ biller: this.route.params.biller_id }).then((res: any) => {
                this.loading = false;
                if (res.success) {
                    if (res.data.bailli_delegue.id !== null) this.main_contacts.push(res.data.bailli_delegue);
                    if (res.data.argentier.id !== null) this.main_contacts.push(res.data.argentier);

                    this.additional_contacts = res.data.biller_contacts;
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while getting contacts.",
                    });
                }
            });
        },
        addOperation(values: any) {
            let formattedPrice;

            if (values.opeType === "debit") formattedPrice = -parseFloat(values.opePrice);
            else formattedPrice = parseFloat(values.opePrice);

            const payload = {
                biller: parseInt(this.route.params.biller_id as string),
                perimeter: values.opePerimeter.toUpperCase(),
                label: values.opeLabel,
                relevant_year: values.opeRelevantYear,
                description: values.opeDescription,
                is_credit: values.opeType === "credit" ? true : false,
                quantity: parseInt(values.opeQuantity),
                unit_price: formattedPrice,
            };

            accountingApi.addOperation(payload).then((res: any) => {
                if (res.success) {
                    ElMessage({
                        type: "success",
                        message: "Operation added successfully.",
                    });
                    this.getFields();
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while adding operation.",
                    });
                }
            });
            this.closeOperationModal();
        },
        deleteOperation(id: any) {
            accountingApi.deleteOperation(id).then((res) => {
                if (res.success) {
                    ElMessage({
                        type: "success",
                        message: "Operation deleted successfully.",
                    });
                    this.getFields();
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occured while deleting operation.",
                    });
                }
            });
        },
        addContact(values: any) {
            this.closeContactsModal();
            var payload = {};
            if (values.contactType === "extern") {
                payload = {
                    biller: parseInt(this.route.params.biller_id as string),
                    firstname: values.contactFirstname,
                    lastname: values.contactLastname,
                    national_bailliage: values.contactType === "extern" ? values.contactBailliage : null,
                    email: values.contactEmail,
                    mail_to: values.contactTo,
                    status: this.newContact.status,
                    is_all_biller: this.newContact.add_to_all,
                };
            } else {
                payload = {
                    biller: parseInt(this.route.params.biller_id as string),
                    member_id: values.contactName,
                    mail_to: values.contactTo,
                    status: this.newContact.status,
                    is_all_biller: this.newContact.add_to_all,
                };
            }

            accountingApi.addContact(payload).then((res: any) => {
                if (res.success) {
                    this.listContacts();
                    this.closeContactsModal();
                    ElMessage({
                        type: "success",
                        message: "Contact added.",
                    });
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while adding contact.",
                    });
                }
            });
        },
        setContactForUpdate(contact) {
            this.editing = true;

            if (contact.type === "member") this.newContactName = contact.userprofile.firstname + " " + contact.userprofile.lastname;

            this.newContact = {
                id: contact.id,
                type: contact.type,
                userprofile: {
                    lastname: contact.userprofile.lastname,
                    firstname: contact.userprofile.firstname,
                } as any,
                bailliage: contact.userprofile.national_bailliage.id,
                email: contact.email,
                status: contact.status.toString(),
                to: "cc",
            } as any;
        },
        updateContact() {
            var payload = {
                id: this.newContact.id,
                type: this.newContact.type,
                lastname: this.newContact.userprofile.lastname,
                firstname: this.newContact.userprofile.firstname,
                email: this.newContact.email,
                status: this.newContact.status,
            } as any;

            if (payload.type === "extern") {
                payload.national_bailliage = this.newContact.bailliage;
            }
            accountingApi.updateContact(payload, payload.id).then((res: any) => {
                if (res.success) {
                    this.listContacts();
                    this.closeContactsModal();
                    ElMessage({
                        type: "success",
                        message: "Contact updated.",
                    });
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while updating contact.",
                    });
                }
            });
        },
        deleteContact(id) {
            ElMessageBox.confirm("Are you sure you want to delete this contact?", "Confirmation", {
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
                customClass: "custom-modal",
                cancelButtonClass: "cancel-modal",
                dangerouslyUseHTMLString: true,
            })
                .then(() => {
                    accountingApi.deleteContact(id).then((res: any) => {
                        if (res.success) {
                            this.listContacts();
                            ElMessage({
                                type: "success",
                                message: "Contact deleted.",
                            });
                        } else {
                            ElMessage({
                                type: "error",
                                message: "An error occurred while deleting contact.",
                            });
                        }
                    });
                })
                .catch(() => {
                    return; // Cancel
                });
        },
        loadRemoteMembers(query) {
            return new Promise((resolve, reject) => {
                if (query !== "" && query.length >= 2) {
                    this.remoteMembers = [];
                    membersApi.listRemote({ query: query }).then((response) => {
                        if (response.success) {
                            response.data.map((remoteMember) => {
                                this.remoteMembers.push({
                                    id: remoteMember.id,
                                    firstname: remoteMember.userprofile.firstname,
                                    lastname: remoteMember.userprofile.lastname,
                                    email: remoteMember.contact ? remoteMember.contact.email : remoteMember.contact_pro ? remoteMember.contact_pro.email : "",
                                    code: remoteMember.code,
                                });
                            });
                            resolve("");
                        } else {
                            reject("");
                        }
                    });
                } else {
                    this.remoteMembers = [];
                    resolve("");
                }
            });
        },
        querySearchAsync(queryString, cb) {
            this.loadRemoteMembers(queryString).then(() => {
                cb(this.remoteMembers);
            });
        },
        handleSelect(item: any) {
            this.newContact.userprofile.lastname = item.lastname;
            this.newContact.userprofile.firstname = item.firstname;
            this.newContact.userprofile.id = item.id;
            this.newContact.email = item.email;
            this.newContactName = item.firstname + " " + item.lastname;
            this.remoteMembers = [];
        },
    },
});
